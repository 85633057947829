import React, { useState } from "react";
import { Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function Foto({ data }) {
    const { imagen } = data;
    const [show, setShow] = useState(false);
    // const [image, setImage] = useState({});

    return (
        <div className="m-2 col-5 col-lg-3">
            <Image className="" src={imagen} fluid alt="Imagen no encontrada" onClick={() => {
                            setShow(true);
                        }}
                        style={{ cursor: "pointer" }}/>
            <Modal show={show} onHide={() => setShow(false)} size="lg" centered className="">
                <Modal.Body>
                    <div>
                        <img src={imagen} alt="Imagen no encontrada" className="w-100" />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
