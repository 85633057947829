import "./App.css";
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import NuestrasCasas from "./pages/NuestrasCasas";
import Financiacion from "./pages/Financiacion";
import NavReact from "./components/NavReact";
import QuienesSomos from "./pages/QuienesSomos";
import Contacto from "./pages/Contacto";
import Wsp from "./components/Wsp";
import Galeria from "./pages/Galeria";
import CarpetaTecnica from "./pages/CarpetaTecnica";


function App() {
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <HashRouter>
        <ScrollToTop />
        <NavReact />
        <Wsp />
        <Routes >
          <Route exact path="/" element={<Home />} />
          <Route path="/nuestrascasas" element={<NuestrasCasas />} />
          <Route path="/financiacion" element={<Financiacion />} />
          <Route path="/quienessomos" element={<QuienesSomos />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/galeria" element={<Galeria />} />
          <Route path="/carpetaTecnica" element={<CarpetaTecnica />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
