import Accordion from "react-bootstrap/Accordion";

const arrayTextos = [
    {
        id: 1,
        titulo: "Descripción general del sistema",
        descripcion: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis ipsa omnis itaque officiis! Hic rerum iure id! Aut sed consequuntur eligendi eveniet magnam quibusdam pariatur placeat quam ad. Temporibus, sint.`,
    },
    {
        id: 2,
        titulo: "Descripción de los elementos y materiales componentes del sistema",
        descripcion: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis ipsa omnis itaque officiis! Hic rerum iure id! Aut sed consequuntur eligendi eveniet magnam quibusdam pariatur placeat quam ad. Temporibus, sint.`,
    },
    {
        id: 3,
        titulo: "Poceso de fabricación, trasporte y almacenamiento",
        descripcion: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis ipsa omnis itaque officiis! Hic rerum iure id! Aut sed consequuntur eligendi eveniet magnam quibusdam pariatur placeat quam ad. Temporibus, sint.`,
    },
    {
        id: 4,
        titulo: "Almacenamiento y transporte",
        descripcion: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis ipsa omnis itaque officiis! Hic rerum iure id! Aut sed consequuntur eligendi eveniet magnam quibusdam pariatur placeat quam ad. Temporibus, sint.`,
    },
    {
        id: 5,
        titulo: "Montaje",
        descripcion: `5-1. Las tareas previas al montaje consisten en la limpieza y nivelación del terreno.

5-2. Ejecución de platea de fundición:
Se ejecuta una platea de hormigón armado de 15 cm de espesor promedio, de dimensiones tales que queden aproximadamente 50 cm 
desde el borde de los paneles para poder apoyar la mampostería. La misma se realiza con hormigón de características similares a un H17 
con malla electro soldada de 150mm x 150mm x 6.00mm para la repartición de esfuerzos.

5-3. Posicionamiento de paneles:
Se ubican los paneles sobre la platea para formar el sector dormitorio-baño. Se comienza por una esquina para poder escuadrar y aplomar 
los paneles sin que se produzca el volteo.
Posicionados los paneles (teniendo en cuenta la línea de referencia) se procede a la fijación inicial mediante cinco o seis tornillos de 2 ½'' 
colocados con atornillador en puntos ya predeterminados.
La estructura de cerramiento se rigidiza con los paneles de cielorraso que se fijan con tornillos de 3'' colocados con atornillador en puntos ya 
predeterminados.
Simultáneamente se colocan los puntales de madera bajo encuentro de paneles de cielorraso con el objeto de nivelar y vincular adecuadamente 
los paneles entre si.`,
    },
];

export default function AcordeonCT() {
    return (
        <div className="container carpeta-tecnica">
            <Accordion>
                {arrayTextos.map((textos) => (
                    <Accordion.Item eventKey={textos.id} className="my-3">
                        <Accordion.Header>
                            <h5 className="color-blanco d-flex align-items-center justify-content-center mb-0">
                                {textos.id} - {textos.titulo}
                            </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                            <pre className="tamaño-mas-chico peso-Medium color-blanco pre-wrap">
                                {textos.descripcion}
                            </pre>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
}
