import React from "react";
import FinanciacionContenido from "../components/FinanciacionContenido";


export default function Financiacion() {
  return (
      <>
        <FinanciacionContenido />
      </>
  );
}
