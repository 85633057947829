import React from "react";
import { Carousel, Image } from "react-bootstrap";
import vertical from "../assets/img/vertical.png";
import horizontal from "../assets/img/horizontal.png";

export default function EntregadasCarousel() {
  return (
    <div>
      <Carousel indicators={true} interval={null}>
        <Carousel.Item>
          <div className=" d-flex align-items-center">
            <Image
              className="d-none d-sm-block imagen-carousel"
              src={horizontal}
              alt="slide 1"
              fluid
            />
            <Image
              className="d-sm-none d-block imagen-tama-carousel "
              src={vertical}
              alt=" slide 1"
              fluid
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className=" d-flex align-items-center">
            <Image
              className="d-none d-sm-block imagen-carousel"
              src={horizontal}
              alt="slide 2"
              fluid
            />
            <Image
              className="d-sm-none d-block imagen-tama-carousel "
              src={vertical}
              alt=" slide 2"
              fluid
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className=" d-flex align-items-center">
            <Image
              className="d-none d-sm-block imagen-carousel"
              src={horizontal}
              alt="slide 3"
              fluid
            />
            <Image
              className="d-sm-none d-block imagen-tama-carousel "
              src={vertical}
              alt=" slide 3"
              fluid
            />
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
