import React from "react";
import Casa from "./Casa";

const casas = [
    {
        id: 1,
        imagen1:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607539/Nuestras%20Casas/Linea%20Ladrillo/DSC_0063_akko1u.png",
        imagen2:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607522/Nuestras%20Casas/Linea%20Ladrillo/WhatsApp_Image_2022-06-22_at_5.21.18_PM_o6c1ci.jpg",
        imagen3:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607522/Nuestras%20Casas/Linea%20Ladrillo/WhatsApp_Image_2022-06-22_at_5.21.58_PM_uk6bw1.jpg",
        imagen4: "",
        imagen5: "",
        titulo: "LINEA LADRILLO",
        iconos: "https://res.cloudinary.com/dgckcowjy/image/upload/v1664233992/Nuestras%20Casas/Iconos/Group_19_d5ia2e.png",
        descripcion: `Estas construcciones son hechas en ladrillo macizo con terminaciones modernas, gran ventaja que amalgama una estética clásica con innovación y tecnología en los materiales. Su cielorraso de madera aporta calidez, y sus aberturas de aluminio y puertas placa en el interior contribuyen a generar un espacio fresco y natural. `,
    },
    {
        id: 2,
        imagen1:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606615/Nuestras%20Casas/LInea%20Americana/WhatsApp_Image_2022-07-16_at_10.13.35_AM_1_bzijul.jpg",
        imagen2:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606615/Nuestras%20Casas/LInea%20Americana/WhatsApp_Image_2022-07-16_at_10.13.35_AM_nmir5o.jpg",
        imagen3:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606615/Nuestras%20Casas/LInea%20Americana/WhatsApp_Image_2022-07-16_at_10.13.36_AM_1_cxr5c5.jpg",
        imagen4:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606615/Nuestras%20Casas/LInea%20Americana/WhatsApp_Image_2022-07-16_at_10.13.36_AM_f6ze35.jpg",
        imagen5: "",
        titulo: "LINEA AMERICANA",
        iconos: "https://res.cloudinary.com/dgckcowjy/image/upload/v1664233992/Nuestras%20Casas/Iconos/Group_19_d5ia2e.png",
        descripcion: `Es una apuesta clara y moderna, donde no solo se respeta la tradicional estética americana, sino que, a su vez, se innova con materiales de ecología sustentable y protección térmica, que la convierten en una opción de bajo consumo energético.`,
    },
    {
        id: 3,
        imagen1:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606782/Nuestras%20Casas/Linea%20Simil%20Tronco/IMG-20220627-WA0038_qlsz8d.jpg",
        imagen2: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606786/Nuestras%20Casas/Linea%20Simil%20Tronco/IMG-20220627-WA0031_zdyltc.jpg",
        imagen3: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606785/Nuestras%20Casas/Linea%20Simil%20Tronco/IMG-20220627-WA0044_ubjvqa.jpg",
        imagen4: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606788/Nuestras%20Casas/Linea%20Simil%20Tronco/IMG-20220627-WA0033_grgnao.jpg",
        imagen5: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606809/Nuestras%20Casas/Linea%20Simil%20Tronco/IMG-20220627-WA0030_bqvz1m.jpg",
        titulo: "LINEA SIMIL TRONCO",
        iconos: "https://res.cloudinary.com/dgckcowjy/image/upload/v1664233992/Nuestras%20Casas/Iconos/Group_19_d5ia2e.png",
        descripcion: `Una de sus características distintivas es su revestimiento exterior en machimbre simil tronco de pino, que transmite una sensación de un lugar acogedor y seguro. 
        Sus aberturas, su puerta principal y sus ventanas están hechas en madera de algarrobo, garantía de durabilidad.
        `,
    },
    {
        id: 4,
        imagen1:
            "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606731/Nuestras%20Casas/Linea%20Minimalista/WhatsApp_Image_2022-06-22_at_5.28.36_PM_1_ugz0dc.jpg",
        imagen2: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606768/Nuestras%20Casas/Linea%20Minimalista/WhatsApp_Image_2022-06-22_at_5.28.36_PM_2_v0lgri.jpg",
        imagen3: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606752/Nuestras%20Casas/Linea%20Minimalista/WhatsApp_Image_2022-06-22_at_5.28.37_PM_2_quvhz0.jpg",
        imagen4: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606744/Nuestras%20Casas/Linea%20Minimalista/WhatsApp_Image_2022-06-22_at_5.28.36_PM_3_h2pjhe.jpg",
        imagen5: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665606741/Nuestras%20Casas/Linea%20Minimalista/WhatsApp_Image_2022-06-22_at_5.28.35_PM_e8poiy.jpg",
        titulo: "LINEA MINIMALISTA",
        iconos: "https://res.cloudinary.com/dgckcowjy/image/upload/v1664233992/Nuestras%20Casas/Iconos/Group_19_d5ia2e.png",
        descripcion: `La opción más buscada por los amantes de las líneas elegantes gracias a su moderna fachada. Se suma a su estilo exterior habitaciones con un clima cálido y hogareño. 
        La principal función de los materiales elegidos, entre las que se encuentran sus puertas de pino cepillada y sus ventanas de aluminio, es generar una excelente aislación térmica, acústica e hidrófuga, sin dejar el diseño de lado.
        `,
    },
];

export default function LineasCasas() {
    return (
        <div className="">
            <div className="d-flex mt-5 mb-4 container">
                <h3 className="peso-Bold color-rojo">I</h3>
                <h3 className="peso-Black color-morado ">NUESTRAS CASAS</h3>
            </div>
            <div>
                {casas.map((casa, id) => (
                    <Casa key={id} data={casa} />
                ))}
            </div>
        </div>
    );
}
