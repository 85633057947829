import React from 'react'
import Banner from '../components/Banner'
import Cobertura from '../components/Cobertura'
import ComentariosExcel from '../components/ComentarioExcel'
// import Comentarios from '../components/Comentarios'
import Entrega from '../components/Entrega'
import EntregadasCarousel from '../components/EntregadasCarousel'


export default function Home() {
  return (
    <>
      <Banner />
      <EntregadasCarousel />
      <Entrega />
      <Cobertura />
      {/* <Comentarios /> */}
      <ComentariosExcel />
      </>
  )
}
