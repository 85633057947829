import React from "react";
import LineasCasas from "../components/LineasCasas";

export default function NuestrasCasas() {
    return (
        <div>
            <LineasCasas />
        </div>
    );
}
