import React from 'react'
import FormContacto from '../components/FormContacto'

export default function Contacto() {
  return (
      <div className='fondo-financiacion'>
          <FormContacto />
    </div>
  )
}
