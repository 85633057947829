import React, { useState } from "react";
import Foto from "./Foto";
import Pagination from "../components/Pagination";
import PaginationMobile from "../components/PaginationMobile";
const fotos = [
    {
        id: 1,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665608001/Nuestras%20Casas/Galeria/IMG-20220627-WA0043_bwshie.png",
    },
    {
        id: 2,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665608001/Nuestras%20Casas/Galeria/IMG-20220627-WA0044_myzyex.png",
    },
    {
        id: 3,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607999/Nuestras%20Casas/Galeria/IMG-20220627-WA0041_bov5sq.png",
    },
    {
        id: 4,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607999/Nuestras%20Casas/Galeria/IMG-20220627-WA0039_hb1wwn.png",
    },
    {
        id: 5,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607998/Nuestras%20Casas/Galeria/IMG-20220627-WA0042_n02mc6.png",
    },
    {
        id: 6,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607998/Nuestras%20Casas/Galeria/IMG-20220627-WA0038_vjfcrv.png",
    },
    {
        id: 7,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607998/Nuestras%20Casas/Galeria/IMG-20220627-WA0037_mfd2pi.png",
    },
    {
        id: 8,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607997/Nuestras%20Casas/Galeria/IMG-20220627-WA0036_viqqnr.png",
    },
    {
        id: 9,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607996/Nuestras%20Casas/Galeria/IMG-20220627-WA0034_cmfhwe.png",
    },
    {
        id: 10,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607996/Nuestras%20Casas/Galeria/IMG-20220627-WA0029_edxbwt.png",
    },
    {
        id: 11,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607996/Nuestras%20Casas/Galeria/IMG-20220627-WA0033_cjqnp7.png",
    },
    {
        id: 12,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607995/Nuestras%20Casas/Galeria/IMG-20220627-WA0031_eaf6c0.png",
    },
    {
        id: 13,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607995/Nuestras%20Casas/Galeria/IMG-20220627-WA0030_xhy2dg.png",
    },
    {
        id: 14,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607995/Nuestras%20Casas/Galeria/IMG-20220627-WA0032_otwfim.png",
    },
    {
        id: 15,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607955/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.12_PM_zbuqtc.png",
    },
    {
        id: 16,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607954/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.12_PM_1_ifpmjh.png",
    },
    {
        id: 17,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607954/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.28.37_PM_2_y3g4qr.png",
    },
    {
        id: 18,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607953/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.28.36_PM_uirgc1.png",
    },
    {
        id: 19,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607952/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.28.36_PM_2_j2ghji.png",
    },
    {
        id: 20,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607952/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.28.36_PM_1_p5tmmm.png",
    },
    {
        id: 21,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607952/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.28.36_PM_3_wqyctj.png",
    },
    {
        id: 22,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607952/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.28.35_PM_jqtngk.png",
    },
    {
        id: 23,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607951/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.16_PM_wzhdlr.png",
    },
    {
        id: 2,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607950/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.15_PM_1_dbbcib.png",
    },
    {
        id: 25,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607950/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.39_PM_a3wid1.png",
    },
    {
        id: 26,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607950/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.15_PM_rzdazf.png",
    },
    {
        id: 27,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607950/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.14_PM_ovduw4.png",
    },
    {
        id: 28,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607949/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.14_PM_2_ilw9jy.png",
    },
    {
        id: 29,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607948/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.14_PM_1_s2pmv6.png",
    },
    {
        id: 30,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607948/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.27.13_PM_1_g2ijdj.png",
    },
    {
        id: 31,
        imagen: "https://res.cloudinary.com/dgckcowjy/image/upload/v1665607835/Nuestras%20Casas/Galeria/WhatsApp_Image_2022-06-22_at_5.21.18_PM_ozbjun.png",
    },
];

export default function GaleriaFotos() {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(9);

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = fotos.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="">
            <div className="d-flex mt-5 mb-4 container">
                <h3 className="peso-Bold color-rojo">I</h3>
                <h3 className="peso-Black color-morado ">GALERIA</h3>
            </div>
            <h5 className="peso-SemiBold color-morado interlineado mt-lg-4 mt-0 container">
                Estamos buscando siempre satisfacer las expectativas de quienes
                confiaron en nosotros para concretar la casa de sus sueños.
            </h5>
            <div className="d-flex flex-wrap justify-content-center my-5">
                {currentPosts.map((foto, id) => (
                    <Foto key={id} data={foto} />
                ))}
            </div>
            <div className="d-none d-sm-flex justify-content-center peso-Black">
                <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={fotos.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            </div>
            <div className="d-flex d-sm-none justify-content-center peso-Black">
                <PaginationMobile
                    postsPerPage={postsPerPage}
                    totalPosts={fotos.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            </div>
        </div>
    );
}
