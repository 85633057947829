import React from "react";
import { Carousel, Image } from "react-bootstrap";

export default function Casa({ data }) {
    const {
        imagen1,
        imagen2,
        imagen3,
        imagen4,
        imagen5,
        iconos,
        titulo,
        descripcion,
    } = data;
    return (
        <div className="mb-5 ">
            <div className="d-flex justify-content-center">
                <Carousel
                    indicators={false}
                    controls={imagen2 === "" ? false : true}
                    className="container"
                    interval={null}
                >
                    <Carousel.Item>
                        <Image
                            className=""
                            src={imagen1}
                            alt="Imagen no encontrada"
                            fluid
                        />
                    </Carousel.Item>
                    {imagen2 === "" ? (
                        ""
                    ) : (
                        <Carousel.Item className="">
                            <Image
                                className=""
                                src={imagen2}
                                alt="Imagen no encontrada"
                                fluid
                            />
                        </Carousel.Item>
                    )}
                    {imagen3 === "" ? (
                        ""
                    ) : (
                        <Carousel.Item className="">
                            <Image
                                className=""
                                src={imagen3}
                                alt="Imagen no encontrada"
                                fluid
                            />
                        </Carousel.Item>
                    )}
                    {imagen4 === "" ? (
                        ""
                    ) : (
                        <Carousel.Item className="">
                            <Image
                                className=""
                                src={imagen4}
                                alt="Imagen no encontrada"
                                fluid
                            />
                        </Carousel.Item>
                    )}
                    {imagen5 === "" ? (
                        ""
                    ) : (
                        <Carousel.Item className="">
                            <Image
                                className=""
                                src={imagen5}
                                alt="Imagen no encontrada"
                                fluid
                            />
                        </Carousel.Item>
                    )}
                </Carousel>
            </div>
            <div className="mt-lg-3 mt-0 container">
                <div className="d-block d-lg-flex align-items-end">
                    <p className="mb-0 peso-Black color-morado me-4">
                        {titulo}
                    </p>
                    <Image
                        className=""
                        src={iconos}
                        fluid
                        alt="Imagen no encontrada"
                    />
                </div>
                <h5 className="peso-SemiBold color-morado interlineado mt-lg-4 mt-0">
                    {descripcion}
                </h5>
            </div>
        </div>
    );
}
