import React from "react";
import { Image } from "react-bootstrap";
import entrega from "../assets/img/empresaconvos.png";
import entregahorizontal from "../assets/img/somoshorizontal.png";

export default function QuienesSomos() {
  return (
    <div>
      <div className="container">
        <div className="pt-4">
          <div className="d-flex">
            <h3 className="px-5 mt-4 peso-Black tamaño-chico color-morado">
              {" "}
              <span className="peso-Bold color-rojo">I</span> ¿QUIENES SOMOS?
            </h3>
          </div>

          <p className="tamaño-mas-chico color-morado-oscuro px-5">
            Somos una empresa pensada y dedicada a satisfacer las necesidades
            habitacionales de los clientes y prestándole especial atención a los
            requerimientos particulares de cada persona. Buscando que cada
            experiencia sea única e irrepetible. Una empresa joven, con raíces
            firmes centradas en el conocimiento, la dedicación y la convicción
            de brindar una complacencia única en este camino de llegar a
            conseguir uno de sus mayores sueños, TENER SU CASA.
          </p>
        </div>
        <div className="pb-5">
          <h3 className="px-5 mt-4 peso-Black color-morado tamaño-chico">
            {" "}
            <span className="peso-Bold tamaño-Medium color-rojo">I</span> ¿QUE HACEMOS?
          </h3>
          <p className="tamaño-mas-chico color-morado px-5">
          VIVIENDAS BELGRANO fabrica y comercializa Viviendas Industrializadas, un producto que combina la construcción tradicional en el exterior 
(lo que brinda estabilidad, proyección y durabilidad) con la construcción en seco en el interior (que permite mayor aislamiento de temperatura 
y de humedad, rapidez de construcción y maniobrabilidad para cualquier cambio o modificación que se requiera).
El Sistema Constructivo está diseñado para que se pueda llevar a cabo en cualquier punto del país, más allá de los distintos climas.
Las viviendas son un producto que se adapta a cualquier diseño de proyecto de una planta, brindando la posibilidad de trabajar 
con los distintos materiales que hoy tenemos a disposición en el mercado, con la posibilidad de incorporar nuevos materiales y tecnologías.
          </p>
        </div>
      </div>

      {/* desktop */}
      <div className="d-none d-md-block">
        <Image className="w-100" src={entregahorizontal} alt="entrega" fluid />
      </div>
      {/* mobile */}
      <div className="d-md-none d-block">
        <div className="text-center">
          <Image
            className="imagen-tama-carousel"
            src={entrega}
            alt="entrega"
            fluid
          />
        </div>
      </div>
    </div>
  );
}
