import { Col, Form, InputGroup, Row, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import Swal from "sweetalert2";
import { useRef } from "react";


export default function FormContacto() {
  const [validated, setValidated] = useState(false);
  const form = useRef();

  /*funcion para mandar msj al gmail*/
  const sendEmail = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === true) {
      e.stopPropagation();
      emailjs
        .sendForm(
          "service_9o7a2tb",
          "template_6whwior",
          e.target,
          "fF01ErimCUFswBv83"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      Swal.fire({
        icon: "success",
        title: "Mensaje enviado con éxito",
        showConfirmButton: false,
        timer: 2000,
      });
      form.reset();
      setValidated(false); //
    } else {
      setValidated(true);
      Swal.fire({
        icon: "error",
        title: "No se pudo enviar el mensaje",
      });
    }
  };

  return (
      <div className="container ">
        <Form
          ref={form}
          noValidate
          validated={validated}
          onSubmit={sendEmail}
          className="mx-auto form-contactanos my-5 pt-4 "
        >
          <div>
            <div className="card-body">
              <Row className="mb-2">
                <Form.Group
                  className="color-morado"
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                >
                  <Form.Label className="color-morado peso-bold tamaño-mas-chico">
                    Nombre
                  </Form.Label>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    required
                    name="name"
                  />
                  <Form.Control.Feedback
                    className="peso-bold tamaño-mas-chico"
                    type="invalid"
                  >
                    Ingrese su nombre completo por favor.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="12" controlId="validationCustom04">
                  <Form.Label className=" color-morado peso-bold tamaño-mas-chico mt-2">
                    Whatsapp
                  </Form.Label>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    required
                    name="cel"
                  />
                  <Form.Control.Feedback
                    className="peso-bold tamaño-mas-chico "
                    type="invalid"
                  >
                    Ingrese su número de WhatsApp por favor.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Label className="color-morado peso-bold tamaño-mas-chico mt-2">
                    Email
                  </Form.Label>
                  <Form.Control
                    className="mb-2"
                    type="email"
                    required
                    name="email"
                  />
                  <Form.Control.Feedback
                    className="peso-bold tamaño-mas-chico "
                    type="invalid"
                  >
                    Ingrese su Email por favor.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group
                  className="color-morado"
                  as={Col}
                  md="12"
                  controlId="validationCustom03"
                >
                  <Form.Label className=" color-morado peso-bold tamaño-mas-chico">
                    Ciudad
                  </Form.Label>
                  <Form.Control
                    className="mb-2"
                    type="text"
                    required
                    name="ciudad"
                  />
                  <Form.Control.Feedback
                    className="peso-bold tamaño-mas-chico"
                    type="invalid"
                  >
                    Ingrese su ciudad por favor.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <Form.Group as={Col} md="12" controlId="validationCustom04">
                  <Form.Label className=" color-morado peso-bold tamaño-mas-chico mt-2 ">
                    Mensaje
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      className="mb-2"
                      as="textarea"
                      name="msj"
                      required
                    />
                    <Form.Control.Feedback
                      className="peso-bold tamaño-mas-chico"
                      type="invalid"
                    >
                      Ingrese un mensaje por favor.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-center padding-fixed-contactanos  my-3 ">
            <Button
              className="boton-contacto border-0 "
              size="sm"
              type="submit"
              value="Send"
            >
              <p className="peso-black tamaño-mas-chico mt-2">Enviar</p>
            </Button>
          </div>
        </Form>
      </div>
  );
}