import React from "react";
import { Image } from "react-bootstrap";
import ReactPlayer from "react-player";
import logo from "../assets/img/logocolor.png";

export default function Banner() {
  return (
    <div className="fondo-banner">
      <div className="text-center">
        <Image className="" src={logo} fluid alt="fondo" />
      </div>

      <div>
        <div className="pb-5">
          <ReactPlayer
            url="https://youtu.be/lHELufzW_4c"
            playing={false}
            muted
            controls={true}
            width="100%"
            height="88vh"
          />
        </div>
      </div>
    </div>
  );
}
