import React from "react";
import { Image } from "react-bootstrap";
import logo from "../assets/img/logofooter.png";
import wsp from "../assets/img/wspblanco.svg"; 
import mail from "../assets/img/mail.svg"; 
import ubicacion from "../assets/img/ubicacion.svg"; 

export default function Footer() {
  return (
    <div className=" text-white mt-auto color-morado-fondo">
      <div className="px-4 pt-4">
        <div className="row">
          {/* Column1 */}
          <div className="col-12 col-lg-4 text-md-start d-flex justify-content-center mx-auto mt-3 mt-md-0 ">
            <ul className="list-unstyled">
              <li className="tamaño-medio text-center ">
                <Image className="w-100" src={logo} alt="logo" fluid />
              </li>
            </ul>
          </div>
          {/* Column2 */}
          <div className="col-12 col-lg-4 text-md-start d-flex justify-content-center mx-auto my-3">
            <ul className="list-unstyled text-white">
              <li>
                <p className="tamaño-mas-chico text-center mb-0">
                  <span><Image src={wsp} alt="logo" fluid /></span> 381-3686628
                </p>
                <p className="tamaño-mas-chico text-center mb-0">
                <span><Image src={mail} alt="logo" fluid /></span> construccionesbelgranosmt@gmail.com
                </p>
                <p className="tamaño-mas-chico text-center mb-0">
                <span><Image src={ubicacion} alt="logo" fluid /></span>Av belgrano 2351
                </p>
                <p className="tamaño-mas-chico text-center mb-0">
                  Tucumán - Argentina
                </p>
              </li>
            </ul>
          </div>
          {/* Column3 */}
          {/* <div className="col-12 col-lg-4 text-center text-md-start d-flex justify-content-center mb-1 mt-2 p-1 ">
          <ul className="list-unstyled">
            <li className=" mb-3 mt-2 tamaño-chico">
          1
            </li>
            <li className="mb-3 tamaño-chico ">
              2
            </li>
            <li className="tamaño-chico">
             3
            </li>
          </ul>
        </div> */}
        </div>
      </div>
      <div className="tamaño-mas-chico ">
        <div className="color-morado-oscuro-fondo text-white">
          <div className="text-center text-md-start my-4 d-flex justify-content-center">
            <div className="py-3">
              <a
                className="text-decoration-none text-white "
                href="https://www.dkzstudio.com/"
                target="_blank"
                rel="noreferrer"
              >
                Product by DKZ studio &copy;{new Date().getFullYear()} Todos los derechos reservados
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
