import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationBasic = ({
    postsPerPage,
    totalPosts,
    paginate,
    currentPage,
}) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    return (
        <Pagination className="pagination ">
            <Pagination.First className="m-0 peso-bold" onClick={() => topFunction(paginate(1))} />
            <Pagination.Prev className="m-0 peso-bold"
                onClick={
                    currentPage === 1
                        ? ""
                        : () => topFunction(paginate(currentPage - 1))
                }
            />
            {pageNumbers.map((number) => (
                <Pagination.Item
                    key={number}
                    active={currentPage === number ? true : false}
                    onClick={() => topFunction(paginate(number))}
                    className="page-link p-0 border-0"
                >
                    <p className="m-0 peso-bold">{number}</p>
                </Pagination.Item>
            ))}
            <Pagination.Next className="m-0 peso-bold"
                onClick={
                    currentPage === Math.ceil(totalPosts / postsPerPage)
                        ? ""
                        : () => topFunction(paginate(currentPage + 1))
                }
            />
            <Pagination.Last className="m-0 peso-bold"
                onClick={() =>
                    topFunction(paginate(Math.ceil(totalPosts / postsPerPage)))
                }
            />
        </Pagination>
    );
};

export default PaginationBasic;
