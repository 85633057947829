import AcordeonCT from "../components/AcordeonCT";

export default function CarpetaTecnica() {
    return (
        <div>
            <div className="d-flex mt-5 mb-4 container">
                <h3 className="peso-Bold color-rojo">I</h3>
                <h3 className="peso-Black color-morado ">CARPETA TÉCNICA</h3>
            </div>
            <AcordeonCT />
        </div>
    );
}
