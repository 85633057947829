import React, { useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Toast, Image, Collapse } from "react-bootstrap";
import estrella from "../assets/img/star.png";
import sinfotocomentario from "../assets/img/sinfotocomentario.jpg";

export default function ComentarioExcelCard({ data }) {
    const [open, setOpen] = useState(false);
    const { id, nombre, comentario, nota, fotoCuadrada, fecha } = data;

    return (
        <div className="">
            <Toast className="fondo-card">
                <Toast.Header className="fondo-card">
                    <div>
                        <Image
                            className="foto-comentario"
                            src={
                                fotoCuadrada === ""
                                    ? sinfotocomentario
                                    : fotoCuadrada
                            }
                            roundedCircle
                            fluid
                            alt="FotoCara"
                        />
                    </div>
                    <div className="ms-4">
                        <div className="d-flex flex-column d-lg-block">
                            <span className="color-morado tamaño-mas-chico peso-SemiBold">
                                {nombre}
                            </span>
                            <span className="color-morado mx-lg-4 mx-0 tamaño-mas-chico peso-SemiBold">
                                {fecha}
                            </span>
                        </div>
                        <div className="my-1">
                            <span className="tamaño-mas-chico peso-SemiBold color-morado">
                                {nota}/5
                            </span>
                            <span className="ms-2">
                                {nota >= 1 ? (
                                    <Image
                                        className="mb-1"
                                        src={estrella}
                                        fluid
                                        alt="Estrellas"
                                    />
                                ) : (
                                    ""
                                )}
                                {nota >= 2 ? (
                                    <Image
                                        className="mb-1"
                                        src={estrella}
                                        fluid
                                        alt="Estrellas"
                                    />
                                ) : (
                                    ""
                                )}
                                {nota >= 3 ? (
                                    <Image
                                        className="mb-1"
                                        src={estrella}
                                        fluid
                                        alt="Estrellas"
                                    />
                                ) : (
                                    ""
                                )}
                                {nota >= 4 ? (
                                    <Image
                                        className="mb-1"
                                        src={estrella}
                                        fluid
                                        alt="Estrellas"
                                    />
                                ) : (
                                    ""
                                )}
                                {nota == 5 ? (
                                    <Image
                                        className="mb-1"
                                        src={estrella}
                                        fluid
                                        alt="Estrellas"
                                    />
                                ) : (
                                    ""
                                )}
                            </span>
                        </div>
                    </div>
                </Toast.Header>
                <Toast.Body>
                    {/* {comentario.length < 110 ? (
                       
                    ) : (
                        <p className=" tamaño-mas-chico peso-SemiBold color-morado">
                            {comentario.substring(0, 100)}
                            <span
                                className={open === true ? "d-none" : ""}
                                onClick={() => setOpen(!open)}
                                aria-controls={id}
                                aria-expanded={open}
                            >
                                ...{" "}
                                <span className="peso-medium" style={{ cursor: "pointer" }}>Leer mas</span>
                            </span>
                            <Collapse in={open}>
                                <span className="tamaño-comentario" id={id}>
                                    {comentario.substring(
                                        90,
                                        comentario.length
                                    )}
                                    <p
                                        className="peso-medium"
                                        onClick={() => setOpen(!open)}
                                        aria-controls="detail-article"
                                        aria-expanded={open}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Leer menos
                                    </p>
                                </span>
                            </Collapse>
                        </p>
                    )} */}
                     <p className=" tamaño-mas-chico peso-SemiBold color-morado">{comentario}</p>
                </Toast.Body>
            </Toast>
        </div>
    );
}
