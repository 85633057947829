import React from "react";
import { Image } from "react-bootstrap";
import fondo from "../assets/img/fondocobertura.png";
import coberturahorizontal from "../assets/img/coberturaescritorio.png"

export default function Cobertura() {
  return (
    <div>
      <div className="d-none d-md-block text-center" >
      <Image
            className="responsive-imagen-cobertura"
            src={coberturahorizontal}
            fluid
            alt="cobertura horizontal"
          />
      </div>
      <div className="mt-5 d-md-none d-block">
        <div className="color-morado container">
          <p className="tamaño-mas-chico peso-Bold px-5"> COBERTURA</p>
          <p className="tamaño-mas-chico px-5">
            Tenemos <span className="peso-Bold ">cobertura </span> en las
            provincias de{" "}
            <span className="peso-Bold ">
              Salta, Santiago del Estero, Catamarca y Tucumán.
            </span>
          </p>
        </div>
        <div className="text-center">
          <Image
            className="responsive-imagen-cobertura"
            src={fondo}
            fluid
            alt="fondo"
          />
        </div>
      </div>
    </div>
  );
}
