import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import brand from "../assets/img/LOGO.png";

export default function NavReact() {
    return (
        <Navbar
            className="mx-0 mx-lg-5 my-4 color-morado-fondo nav-detalles"
            variant="dark"
            expand="lg"
        >
            <Container>
                <Navbar.Brand href="/">
                    <Image className="" src={brand} alt="logo-brand" fluid />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link className="ms-auto" as={Link} to="/nuestrascasas">
                            <h5 className="color-blanco peso-ExtraBold">NUESTRAS CASAS</h5>
                        </Nav.Link>
                        <Nav.Link className="ms-auto" as={Link} to="/financiacion">
                            <h5 className="color-blanco peso-ExtraBold">FINANCIACIÓN</h5>
                        </Nav.Link>
                        <Nav.Link className="ms-auto" as={Link} to="/galeria">
                            <h5 className="color-blanco peso-ExtraBold">GALERIA</h5>
                        </Nav.Link>
                        <Nav.Link className="ms-auto" as={Link} to="/quienessomos">
                            <h5 className="color-blanco peso-ExtraBold">¿QUIÉNES SOMOS?</h5>
                        </Nav.Link>
                        <Nav.Link className="ms-auto" as={Link} to="/carpetaTecnica">
                            <h5 className="color-blanco peso-ExtraBold">CARPETA TÉCNICA</h5>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
