import React from 'react'
import { Button, Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import entrega from "../assets/img/financiacion.png";
import entregahorizontal from "../assets/img/financiabannerhorizontal.png";

export default function FinanciacionContenido() {
  return (
    <div className="text-center">
      <div className="d-none d-lg-block">
      <Image className=" w-100 " src={entregahorizontal} alt="entrega" fluid />
    </div>
    <div className="d-lg-none d-block">
      {" "}
      <Image className=" w-100" src={entrega} alt="entrega" fluid />
    </div>

    <div className="posi-boton">
      <Button as={Link} to="/contacto" className="boton-contacto border-0">
        <div className="d-flex justify-content-center align-self-center">
          <p className="tamaño-mas-chico my-2">Quiero que me llamen</p>
        </div>
      </Button>
    </div> 
  </div>
  )
}
