import React from "react";
import { Image } from "react-bootstrap";
import entrega from "../assets/img/entrega.png";
import entregahorizontal from "../assets/img/entregahorizontal.png";

export default function Entrega() {
  return (
    <div className="text-center">
      <div className="d-none d-sm-block">
        <Image
          className="w-100"
          src={entregahorizontal}
          alt="entrega"
        />
      </div>
      <div className="d-sm-none d-block">
        <Image
          className=" imagen-tama-carousel"
          src={entrega}
          alt="entrega"
          fluid
        />
      </div>
    </div>
  );
}
