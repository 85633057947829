import axios from "axios";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import {EffectCoverflow,Keyboard, Pagination, Navigation } from "swiper";
import ComentarioExcelCard from "./ComentarioExcelCard";


function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function ComentariosExcel() {
  const { width } = useWindowDimensions();
  const [comentarios, setComentarios] = useState([]);

  useEffect(() => {
    const getComentarios = async () => {
      const response = await axios.get(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vRTCBg5WX8Imb7WSMpCMHOjbmJ2Lx_Ab2t_tDcWrjQsUf3hjrqgI0A_Xsn4d6Ej1ihVdKGit2_LNVGn/pub?gid=1167268497&single=true&output=csv"
      );

      const articulos = Papa.parse(response.data, { header: true });

      setComentarios(articulos.data);
    };
    getComentarios();
  }, []);
  return (
    <div>
      <div className="text-center peso-ExtraBold container d-lg-block d-none">
        <p className="tamaño-chico color-morado">
          OPINIONES DE NUESTROS CLIENTES
        </p>
      </div>
      <div className="container d-block d-lg-none">
        <p className="tamaño-chico peso-ExtraBold  color-morado">
        OPINIONES DE NUESTROS CLIENTES
        </p>
      </div>
      <div className="d-flex flex-wrap justify-content-between pb-5 cursor">
        <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={width >= 770 ? width / 475 : width / 400}
       
        keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
        modules={[EffectCoverflow, Pagination,Navigation,Keyboard]}
          className="mySwiper pb-5"
        
        >
          {comentarios.map((comentario, id) => (
            <SwiperSlide className="d-flex flex-column">
              <ComentarioExcelCard key={id} data={comentario} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}